<template>
	<a id="to-top" ref="toTop" href="#intro" @click.prevent="scroll()">
		<font-awesome-icon icon="fa-light fa-arrow-down" size="1x" />
	</a>
</template>

<script setup>
const offset = 350;
const toTop = ref(null);
const scrollToEl = ref('#intro');

const handleScroll = () => {
	if (window.pageYOffset >= offset) {
		toTop.value.classList.add('rotate');
		scrollToEl.value = '#';
	} else {
		toTop.value.classList.remove('rotate');
		scrollToEl.value = '#intro';
	}
};

const scroll = () => {
	const intro = document.getElementById('intro');
	const offsetTop = intro ? intro.offsetTop : offset;
	const top = scrollToEl.value === '#intro' ? offsetTop : 0;

	window.scroll({ top, behavior: 'smooth' });
};

onBeforeMount(() => {
	window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss" scoped>
#to-top {
	position: fixed;
	top: auto;
	right: 25px;
	bottom: 15px;
	z-index: 2;
	width: 55px;
	height: 55px;
	line-height: 58px;
	text-align: center;
	cursor: pointer;
	box-shadow: 0 0 5px rgb(0 0 0 / 15%);
	font-size: 20px;
	transition: all 0.4s ease-out;
	background-color: var(--primary-background-color);

	&:hover {
		transform: scale(1.1);
	}

	&.rotate {
		transform: rotate(180deg);

		&:hover {
			transform: rotate(180deg) scale(1.1);
		}
	}
}

@media (max-width: 960px) {
	#to-top {
		bottom: 85px;
		right: 15px;

		&:hover {
			transform: scale(1);
		}

		&.rotate {
			&:hover {
				transform: rotate(180deg);
			}
		}
	}
}
</style>
